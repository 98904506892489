var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("div", {
    staticClass: "sizeNum"
  }, [_c("div", {
    staticClass: "sizetype"
  }, [_vm._v("SIZE")]), _vm._l(_vm.sizeList, function (item) {
    return _c("div", {
      key: item.id,
      staticClass: "sizeNum_t"
    }, [_vm._v(" " + _vm._s(item.size) + " ")]);
  })], 2), _c("div", {
    staticClass: "size_btn"
  }, [_c("a-button", {
    staticClass: "btn btn_l",
    attrs: {
      size: "large"
    },
    on: {
      click: _vm.cancle
    }
  }, [_vm._v(_vm._s("取消"))])], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };