var columns = [{
  title: '类目',
  dataIndex: 'parentcategoryname',
  key: 'parentcategoryname',
  width: '20%',
  scopedSlots: {
    customRender: 'parentcategoryname'
  }
}, {
  title: '品牌名称',
  dataIndex: 'brandname',
  key: 'brandname',
  width: '20%',
  scopedSlots: {
    customRender: 'brandname'
  }
}, {
  title: '款式',
  dataIndex: 'type',
  key: 'type',
  width: '20%',
  scopedSlots: {
    customRender: 'type'
  }
}, {
  title: '尺码',
  dataIndex: 'count',
  key: 'count',
  width: '20%',
  scopedSlots: {
    customRender: 'count'
  }
}, {
  title: '备注',
  dataIndex: 'memo',
  key: 'memo',
  width: '20%',
  scopedSlots: {
    customRender: 'memo'
  },
  ellipsis: true
}, {
  title: '操作',
  key: 'action',
  dataIndex: 'action',
  fixed: "right",
  width: 200,
  scopedSlots: {
    customRender: 'action'
  }
}];
export { columns };