import { SizeApi } from "@/api";
export default {
  data: function data() {
    return {
      sizeList: [],
      sizeItem: false,
      size: ""
    };
  },
  mounted: function mounted() {
    this.getSize();
  },
  methods: {
    addSize: function addSize() {
      this.sizeItem = true;
    },
    cancle: function cancle() {
      this.$emit("handleAddCancle");
    },
    submit: function submit() {
      var _this = this;
      if (this.size) {
        SizeApi.savesyscurrencysize({
          size: this.size
        }).then(function (res) {
          if (res) {
            _this.toast("添加成功", "success");
            // this.$emit("handleAddCancle");
            _this.getSize();
          }
        });
      } else {
        this.$emit("handleAddCancle");
      }
    },
    getSize: function getSize() {
      var _this2 = this;
      SizeApi.getsyscurrencysize().then(function (res) {
        _this2.sizeList = res;
      });
    }
  }
};