import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("a-card", {
    staticStyle: {
      "overflow-x": "scroll"
    },
    attrs: {
      bordered: false
    }
  }, [_c("a-form", {
    attrs: {
      form: _vm.createForm,
      layout: "inline"
    }
  }, [_c("FormItems", {
    attrs: {
      searchKeys: _vm.searchKeys,
      getDataWithKey: _vm.getDataWithKey,
      onSelectChange: _vm.handleProvinceChange
    },
    scopedSlots: _vm._u([{
      key: "button",
      fn: function fn() {
        return [_c("a-button", {
          attrs: {
            type: "primary",
            size: "large"
          },
          on: {
            click: _vm.handleSearch
          }
        }, [_vm._v("查询")]), _c("a-button", {
          staticStyle: {
            "margin-left": "20px",
            border: "0",
            background: "#f5f5f5"
          },
          attrs: {
            size: "large"
          },
          on: {
            click: _vm.handleReset
          }
        }, [_vm._v("重置")])];
      },
      proxy: true
    }])
  })], 1)], 1), _c("a-card", {
    staticStyle: {
      "margin-top": "24px"
    },
    attrs: {
      bordered: false
    }
  }, [_c("a-button", {
    directives: [{
      name: "hasPermission",
      rawName: "v-hasPermission",
      value: ["size:btn:add"],
      expression: "['size:btn:add']"
    }],
    staticStyle: {
      "margin-bottom": "20px"
    },
    attrs: {
      type: "primary",
      size: "large"
    },
    on: {
      click: _vm.handleAdd
    }
  }, [_vm._v("新增")]), _vm.commonSize ? _c("a-button", {
    directives: [{
      name: "hasPermission",
      rawName: "v-hasPermission",
      value: ["size:btn:add"],
      expression: "['size:btn:add']"
    }],
    staticStyle: {
      "margin-left": "20px",
      border: "0",
      color: "#3270f5",
      background: "#f5f5f5"
    },
    attrs: {
      type: "primary",
      size: "large"
    },
    on: {
      click: _vm.generalSize
    }
  }, [_vm._v("通用尺码")]) : _vm._e(), _c("a-table", {
    attrs: {
      pagination: false,
      dataSource: _vm.dataSource,
      columns: _vm.columns,
      rowKey: function rowKey(it, i) {
        return it.id;
      },
      loading: _vm.loading
    },
    scopedSlots: _vm._u([_vm._l(_vm.columns, function (_ref) {
      var key = _ref.key;
      return {
        key: key,
        fn: function fn(text, record, index) {
          return [key == "action" ? _c("span", [_c("a", {
            directives: [{
              name: "hasPermission",
              rawName: "v-hasPermission",
              value: ["size:btn:view"],
              expression: "['size:btn:view']"
            }],
            on: {
              click: function click($event) {
                return _vm.detailClick(record.id, record.parentcategoryid);
              }
            }
          }, [_vm._v("查看")]), _c("span", {
            directives: [{
              name: "hasPermission",
              rawName: "v-hasPermission",
              value: ["size:btn:edit"],
              expression: "['size:btn:edit']"
            }]
          }, [_c("a-divider", {
            attrs: {
              type: "vertical"
            }
          }), _c("a", {
            on: {
              click: function click($event) {
                return _vm.editorClick(record.id, record.parentcategoryid);
              }
            }
          }, [_vm._v("修改")])], 1), _c("span", {
            directives: [{
              name: "hasPermission",
              rawName: "v-hasPermission",
              value: ["size:btn:delete"],
              expression: "['size:btn:delete']"
            }]
          }, [_c("a-divider", {
            attrs: {
              type: "vertical"
            }
          }), _c("a", {
            on: {
              click: function click($event) {
                return _vm.handleDelete(record.id);
              }
            }
          }, [_vm._v("删除")])], 1)]) : key == "parentcategoryname" ? _c("span", [_c("span", [_vm._v(_vm._s("".concat(record.parentcategoryname || "--", " \u2014 ").concat(record.categoryname || "--")))])]) : key == "type" ? _c("span", [_vm.sexSizeType[record.type] ? _c("span", [_vm._v(_vm._s("".concat(_vm.sexSizeType[record.type].name) || "---"))]) : _c("span", [_vm._v("---")])]) : key == "memo" ? _c("span", [_c("a-tooltip", {
            attrs: {
              placement: "topLeft"
            }
          }, [_c("template", {
            staticStyle: {
              color: "#232323"
            },
            slot: "title"
          }, [_vm._v(" " + _vm._s(record.memo) + " ")]), _c("span", [_vm._v(_vm._s(record.memo || "---"))])], 2)], 1) : _c("span", [_vm._v(_vm._s(_vm.filterRecordValue(record[key])))])];
        }
      };
    })], null, true)
  }), _c("pagination", {
    attrs: {
      total: _vm.total,
      page: _vm.page,
      size: _vm.size,
      onPageSizeChange: _vm.onPageSizeChange,
      onShowSizeChange: _vm.onShowSizeChange
    },
    on: {
      "update:page": function updatePage($event) {
        _vm.page = $event;
      },
      "update:size": function updateSize($event) {
        _vm.size = $event;
      }
    }
  })], 1), _c("DelUpdate", {
    attrs: {
      tipsDesc: _vm.tipsDesc,
      isDelete: true,
      comVisible: _vm.isDeleteVisible
    },
    on: {
      cancle: _vm.handleCancle,
      submit: _vm.handleSubmit
    }
  }), _c("DPopup", {
    attrs: {
      title: "通用尺码",
      comVisible: _vm.generalVisible
    },
    scopedSlots: _vm._u([{
      key: "form",
      fn: function fn() {
        return [_c("GeneralSize", {
          on: {
            handleAddCancle: _vm.handleAddCancle,
            handleAddSubmit: _vm.handleAddSubmitSuccess
          }
        })];
      },
      proxy: true
    }])
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };